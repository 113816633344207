import connect from "react-redux/es/connect/connect";
import {currentOrganization, currentUser} from "redux/selectors";
import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    LinearProgress,
    Slide,
    Typography,
    Grid
} from "@material-ui/core";
import {CloseRounded as CloseIcon} from "@material-ui/icons";
import React from "react";
import * as WebUtils from "../utils/WebUtils";
import {migrateRevrecUserToProductService} from "../rest/API";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function MigrateToChargebeeSignIn(props) {
    const {currentOrganization, currentUser} = props;
    const [open, setOpen] = React.useState(true);
    const [working, setWorking] = React.useState(false);

    const migrateUser = async () => {
        try {
            setWorking(true);

            let response = await migrateRevrecUserToProductService(currentOrganization.id);
            window.location.href = response.migration_url;

            setWorking(false);
        } catch (e) {
            setWorking(false);
            WebUtils.showError(e);
        }
    };

    return (
        <>
            <Dialog
                onClose={migrateUser}
                open={open}
                TransitionComponent={Transition}
                maxWidth={"sm"}>

                <DialogTitle
                    style={{
                        padding: "24px 16px 0px 16px"
                    }}>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center"
                        }}>
                        <Typography
                            variant={"h4"}
                            color={"primary"}
                            style={{paddingLeft: "10px"}}>
                            Reset Password!!
                        </Typography>
                    </div>
                </DialogTitle>
                <DialogContent
                    style={{
                        overflowY: "auto",
                        padding: "0px 16px 24px 16px"
                    }}>
                    {working && <LinearProgress/>}
                    {!working && (
                        <Grid container>
                            <Grid item xs={12}>
                                <Typography
                                    variant="body1"
                                    color={"textSecondary"}>
                                    We are consolidating users to Chargebee Single Sign On.
                                    We are redirecting you to Reset Password.
                                    <br/>
                                    <br/>
                                </Typography>
                            </Grid>
                            <Grid item xs={12} style={{textAlign: "center"}}>
                                <Button
                                    id={"extendMyTrial"}
                                    size="medium"
                                    variant="contained"
                                    color="primary"
                                    onClick={migrateUser}>
                                    Ok
                                </Button>
                            </Grid>
                        </Grid>
                    )}
                </DialogContent>
            </Dialog>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        currentOrganization: currentOrganization(state),
        currentUser: currentUser(state)
    };
};

export default connect(mapStateToProps)(MigrateToChargebeeSignIn);
